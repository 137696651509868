import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Row, Col, Card } from 'antd';
import Lightbox from 'lightbox-react';

import Layout from '../components/layout';

import 'lightbox-react/style.css';
import SEO from "../components/seo"

const pictures = [
	'https://picsum.photos/1920/1080',
	'https://picsum.photos/1920/1080',
	'https://picsum.photos/1920/1080',
	'https://picsum.photos/1920/1080',
	'https://picsum.photos/1920/1080',
	'https://picsum.photos/1920/1080'
];

const thumbnails = [
	'https://picsum.photos/240/135',
	'https://picsum.photos/240/135',
	'https://picsum.photos/240/135',
	'https://picsum.photos/240/135',
	'https://picsum.photos/240/135',
	'https://picsum.photos/240/135'
];

const Customs = () => {
	const [ fullscreenGallery, setFullscreenGallery ] = useState(false);
	const [ fullscreenIndex, setFullscreenIndex ] = useState(null);

	const toggleFullscreenGallery = (index = null) => {
		setFullscreenGallery(!fullscreenGallery);
		setFullscreenIndex(index);
	}

	return (
		<StaticQuery
			query={graphql`
		      query {
		        jeremy: file(relativePath: { eq: "jeremy.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        benoit: file(relativePath: { eq: "benoit.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        indigo: file(relativePath: { eq: "indigo.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 400) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		      }
		    `}
			render={data => (
				<Layout>
					<SEO
						title="Garage"
						description="Venez découvrir notre garage et notre showroom de motos customs à Bernex !"
						lang="fr"
						url="/garage"
					/>

					<div className="box-container first">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>NOTRE ÉQUIPE</h2>

							<Row type="flex" justify="space-around" gutter={16}>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Card
										hoverable
										cover={<Img fluid={data.jeremy.childImageSharp.fluid} rel="nofollow" />}
									>
										<Card.Meta title="JEREMY" description={
											<>
												<p>Patron de Black Way Motorcycles depuis début 2017.</p>
												<p>J’ai grandi avec la passion des motos depuis mon plus jeune âge, c’est tout naturellement que devenir mécanicien moto est devenu une évidence et de perpétuer un héritage familiale de plusieurs générations.</p>
												<p>Titulaire d’un CFC de mécanicien moto et ancien mécano chez Harley Davidson Genève.</p>
											</>
										} />
									</Card>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Card
										hoverable
										cover={<Img fluid={data.benoit.childImageSharp.fluid} rel="nofollow" />}
									>
										<Card.Meta title="BENOIT" description={
											<>
												<p>Mécanicien en chef chez Black Way Motorcycles depuis l’été 2017.</p>
												<p>Passionné par tout ce qui a des roues et un moteur, c’est sur la mécanique moto que benoit à jeter son dévolu et où il excelle.</p>
												<p>Titulaire d’un CFC de mécanicien motos. Méticuleux et passionné, il saura apporter un travail soigné et de qualité sur vos motos.</p>
											</>
										} />
									</Card>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Card
										hoverable
										/*cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}*/
									>
										<Card.Meta title="ALEXANDER" description={
											<>
												<p>Apprenti depuis le mois de juillet 2019, un jeune plein de motivation et qu’on forme avec ardeur pour l’obtention de son CFC.</p>
											</>
										} />
									</Card>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Card
										hoverable
										cover={<Img fluid={data.indigo.childImageSharp.fluid} rel="nofollow" />}
									>
										<Card.Meta title="INDIGO" description={
											<>
												<p>Compagnon du patron, c’est la mascotte du garage. Vous le trouverez à faire la sieste ou venir vous réclamer des câlins.</p>
												<p>N’ayez crainte en passant la porte de notre atelier il ne vous fera aucun mal, il sera juste un peu pot de colle tant qu’il n’aura pas eu sa caresse.</p>
											</>
										} />
									</Card>
								</Col>
							</Row>

							{/*<h2 style={{ marginTop: 120 }}>LE GARAGE</h2>

							<Row type="flex" justify="center" align="middle" gutter={16}>
								{thumbnails.map((tmb, key) => (
									<Col key={key} xs={12} md={8} xl={6} xxl={4} >
										<Card size="small" onClick={() => toggleFullscreenGallery(key)} hoverable>
											<img src={tmb} style={{ maxWidth: '100%' }} alt="Atelier Black Way Motorcycles - Harley Davidson" />
										</Card>
									</Col>
								))}
							</Row>*/}
						</div>
					</div>

					{
						fullscreenGallery && (
							<Lightbox
								mainSrc={pictures[fullscreenIndex]}
								nextSrc={pictures[(fullscreenIndex + 1) % pictures.length]}
								prevSrc={pictures[(fullscreenIndex + pictures.length - 1) % pictures.length]}
								onCloseRequest={toggleFullscreenGallery}
								onMovePrevRequest={() => setFullscreenIndex((fullscreenIndex + pictures.length - 1) % pictures.length)}
								onMoveNextRequest={() => setFullscreenIndex((fullscreenIndex + 1) % pictures.length)}
							/>
						)
					}
				</Layout>
			)}
		/>
	);
}

export default Customs;
